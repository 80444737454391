@import "../variables";

.tab {
  border: none;
  gap: ptr(4px);
  display: flex;
  color: #AFAFAF;
  font-weight: 500;
  line-height: 100%;
  text-align: center;
  align-items: center;
  font-size: ptr(12px);
  min-height: ptr(28px);
  transition: all 400ms;
  background: transparent;
  border-radius: ptr(4px);
  justify-content: center;
  padding: ptr(4px) ptr(8px);
  p {
    color: #E2E2E2;
    font-weight: 500;
    line-height: 100%;
    font-size: ptr(12px);
  }
  span {
    display: block;
    min-width: $bs;
    padding: ptr(4px);
    line-height: 100%;
    text-align: center;
    border-radius: ptr(7px);
    background-color: #666;
  }
  &:hover,
  &.active {
    background: #3A3A3A;
    span {
      color: $black;
      background-color: $yellow;
    }
  }
}
