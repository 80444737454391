@import "../variables";
.pipeline-page {
  padding: ptr(16px) 0 ptr(56px) 0;
  .pipeline__content {
    display: flex;
    gap: ptr(16px);
  }
  .taskcard__wrap {
    flex-grow: 1;
    padding: ptr(24px);
    margin-top: ptr(24px);
    margin-left: ptr(8px);
    border-radius: ptr(8px);
    border: 1px solid #404040;
    width: calc(100% - ptr(520px));
  }
}
