@import "./variables";
.date-picker-wrap {
  display: flex;
  gap: ptr(16px);
  align-items: flex-start;
  label {
    color: #ccc;
    font-weight: 400;
    font-style: normal;
    font-size: ptr(12px);
    line-height: ptr(18px);
  }

  select {
    display: inline-block;
    font-weight: 400;
    color: #d2d1cf;
    border-radius: 1px;
    background: #222;
    font-style: normal;
    font-size: ptr(14px);
    line-height: ptr(14px);
    padding: ptr(8px) ptr(16px);
    border: 1px solid #3f3f3f;
  }
  .selected-date {
    color: #ccc;
    font-weight: 400;
    font-style: normal;
    padding: ptr(4px);
    font-size: ptr(10px);
    line-height: ptr(18px);
    border: 1px solid #3f3f3f;
    .selected-date-value {
      display: flex;
      gap: ptr(8px);
    }
  }
  .react-datepicker-wrapper {
    max-width: ptr(150px);
    position: relative;
    &::after {
      position: absolute;
      right: 2px;
      bottom: 0;
      left: auto;
      top: 50%;
      content: "";
      display: block;
      width: ptr(24px);
      height: ptr(24px);
      pointer-events: none;
      background-size: contain;
      transform: translateY(-50%);
      background-image: url("../../public/image/calendar.svg");
    }
    input {
      color: #d2d1cf;
      max-width: ptr(150px);
      background-color: #222;
      border: 1px solid #3f3f3f;
      padding: ptr(8px) ptr(16px);
    }
  }
  .start-date,
  .month,
  .end-date,
  .view-report {
    display: flex;
    flex-direction: column;
  }
}
