@import "./variables";
@import "./common";

.main-wrapper {
  .no-data {
    color: #ececec;
    font-weight: 500;
    margin: ptr(24px);
    text-align: center;
    font-size: ptr(16px);
    font-style: normal;
    line-height: ptr(24px);
    letter-spacing: -0.16px;
    font-family: $primary_font;
  }
  .each-row {
    display: flex;
    align-items: center;
    background: #292929;
    margin-bottom: ptr(2px);
    padding: ptr(16px) ptr(24px);
    justify-content: space-between;

    .left {
      gap: 4px;
      display: flex;
      max-width: 416px;
      flex-direction: column;
      align-items: flex-start;

      .row-1 {
        .project-name {
          padding: 5px 16px;
          margin-left: 8px;
          border-radius: 64px;
          background: #3f3f3f;
        }
      }

      .type {
        color: #8e8e8e;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.16px;
      }
    }
  }
}

.outer-wrapper {
  .top {
    gap: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: ptr(16px);
    justify-content: space-between;
    h2 {
      color: #ececec;
      font-weight: 700;
      line-height: 150%;
      font-style: normal;
      font-size: ptr(32px);
      letter-spacing: -0.32px;
      font-family: $primary_font;
    }

    .right {
      gap: 16px;
      display: flex;
      align-items: center;
      align-self: flex-end;
      span {
        flex-grow: 1;
        flex-shrink: 0;
        color: #ececec;
        font-weight: 400;
        font-style: normal;
        font-size: ptr(20px);
        line-height: ptr(24px);
        letter-spacing: -0.2px;
        font-family: $primary_font;
      }

      .btn {
        border: none;
        outline: none;
        cursor: pointer;
        background: none;
        width: ptr(32px);
        height: ptr(32px);
        border-radius: 50%;
        transition: all 0.3s;

        &.next {
          transform: rotate(180deg);
        }

        &:hover {
          background-color: #363636;
        }
      }
    }
  }
}

.date-row-wrapper {
  display: flex;
  padding-left: ptr(24px);
  background: #1c1c1c;
  align-items: center;

  .left {
    text-align: left;
    width: ptr(296px);
    color: rgba(236, 236, 236, 0.5);
    @extend .p;
  }

  .right {
    display: flex;
  }

  .date {
    display: flex;
    width: ptr(120px);
    align-items: center;
    padding: ptr(16px) 0;
    flex-direction: column;
    justify-content: center;
    .day {
      font-weight: 500;
      text-align: center;
      font-size: ptr(14px);
      font-style: normal;
      line-height: ptr(16px);
      letter-spacing: -0.14px;
      color: rgba(236, 236, 236, 0.5);
    }
    span {
      color: #ececec;
      text-align: center;
      @extend .p;
    }

    .total-time {
      margin-top: 8px;
      color: #737373;
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      text-align: center;
      padding: 8px 16px;
      font-style: normal;
      letter-spacing: -0.2px;
      background-color: #2222224d;
    }
  }
}

.cell-outer-wrapper {
  .input-wrapper {
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: 4px;
    background: #292929;

    &.row-to-delete {
      transition: all 0.3s ease;
    }

    /*    &.row-to-delete:hover {
      background: #3d3d3d;
      .delete-row-btn {
        display: block;
      }
    } */
    &.deletable:hover {
      background: #3d3d3d;
      .delete-row-btn {
        display: block;
      }
    }
    &.row-to-delete.leaving {
      opacity: 0;
    }

    .each-item {
      display: flex;
      align-items: center;
      padding: ptr(16px) ptr(24px);

      .log-details {
        display: flex;
        justify-content: space-between;

        .left {
          width: ptr(296px);

          .task-details {
            display: flex;
            flex-direction: column;

            .top-row {
              color: #ececec;
              font-size: ptr(16px);
              font-style: normal;
              font-weight: 500;
              line-height: ptr(24px);
              letter-spacing: -0.16px;
              font-family: $primary_font;
            }
            .bottom-row {
              color: #8e8e8e;
              @extend .sub-heading;
              .task-name {
                font-weight: 400;
                color: #b5afaf;
              }
            }
          }
        }
        .week-container {
          display: flex;
          gap: ptr(40px);
          margin-left: ptr(20px);
        }
      }

      .week-total {
        color: #737373;
        font-weight: 500;
        text-align: center;
        font-style: normal;
        margin: 0 ptr(16px);
        font-size: ptr(20px);
        line-height: ptr(20px);
        letter-spacing: -0.2px;
      }
      .delete-row-btn {
        display: none;
        border: none;
        cursor: pointer;
        background: none;
        width: ptr(32px);
        height: ptr(32px);
        border-radius: 50%;
        margin-left: ptr(8px);
        position: absolute;
        top: ptr(-10px);
        right: ptr(-10px);

        &:disabled {
          display: none;
          cursor: not-allowed;
        }
      }
    }

    .input-outer {
      position: relative;

      &:hover {
        button {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
          cursor: pointer;

          &:hover {
            & + input {
              background-color: #323232;
            }
          }
        }
      }

      button {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 0.4s;
        z-index: 1;
        border: none;
        outline: none;
        top: ptr(-10px);
        width: ptr(20px);
        right: ptr(-10px);
        height: ptr(20px);
        position: absolute;
        border-radius: 50%;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("../../public/image/close-small.svg");
      }

      .input-div {
        @extend .p;
        width: ptr(80px);
        height: ptr(52px);
        color: #ececec;
        text-align: center;
        border-radius: 1px;
        background: #222;
        transition: all 0.4s;
        border: 1px solid #3f3f3f;
        &.read-only {
          border: none;
          pointer-events: none;
        }

        &:hover {
          background: #323232;
        }
      }
    }
  }
}

.today-date,
.today-background {
  background-color: #363636;
}

.total-time-outer-wrapper {
  padding: 0 ptr(24px);
  margin: ptr(16px) 0;
  .content {
    gap: ptr(40px);
    display: flex;
    padding-left: ptr(316px);
    .total-time-per-day {
      @extend .p;
      width: ptr(80px);
      color: #737373;
      text-align: center;
      padding: ptr(14px) 0;
    }
  }
}
