@import "../variables";

.date {
  display: flex;
  gap: ptr(4px);
  align-items: flex-start;
  svg {
    display: block;
  }
  .date__value {
    color: #ccc;
    font-weight: 400;
    font-size: $ss;
    line-height: ptr(16px);
  }
}
