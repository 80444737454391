@import "./variables";

header.main-header {
  padding-top: 16px;
  height: ptr(64px);
  padding-bottom: 16px;
  background-color: #161616;
  border-bottom: 1px solid #222;

  .wrap {
    display: flex;
    justify-content: space-between;

    .right {
      display: flex;
      align-items: center;

      .dropdown-wrap {
        position: relative;
        &:hover {
          .user-details {
            display: flex;
          }
        }

        .user-details {
          gap: 8px;
          display: flex;
          align-items: center;
          text-decoration: none;
          position: relative;

          &::after {
            content: "";
            width: 20px;
            height: 20px;
            display: block;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url("../../public/image/down-arrow.svg");
          }

          p {
            font-size: 16px;
            color: #d2d1cf;
            font-weight: 400;
            line-height: 16px;
            font-style: normal;
          }
          img {
            width: ptr(32px);
            height: ptr(32px);
            border-radius: 50%;
          }
        }

        .user-dropdown {
          width: 100%;
          z-index: 1;
          display: flex;
          top: ptr(32px);
          border-radius: 8px;
          position: absolute;
          background: #333;
          padding: 10px 16px;
          flex-direction: column;

          &::after {
            content: "";
            position: absolute;
            bottom: 100%;
            left: 50%;
            margin-left: -6px;
            border-width: 6px;
            border-style: solid;
            border-color: transparent transparent #333 transparent;
          }

          a {
            margin: 0;
            color: #ececec;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            padding: 8px 0;
            text-decoration: none;
          }
        }
      }

      ul {
        display: flex;
        margin: 0;

        li {
          list-style-type: none;

          a {
            color: #d2d1cf;
            font-size: 16px;
            font-style: normal;
            text-decoration: none;
            font-weight: 400;
            line-height: 16px;
            padding: 10px;
            display: block;

            &:hover {
              color: #fece62;
            }
          }

          .dropdown {
            position: relative;

            a {
              color: #d2d1cf;
              font-size: 16px;
              font-style: normal;
              text-decoration: none;
              font-weight: 400;
              line-height: 16px;
              padding: 10px;
              display: block;

              &:hover {
                color: #fece62;
              }

              &.dropbtn:hover {
                & + .dropdown-content {
                  display: flex;
                }
              }
            }

            .dropdown-content {
              left: 0;
              right: 0;
              display: none;
              position: absolute;
              width: fit-content;
              border-radius: 8px;
              flex-direction: column;
              background-color: #333;

              &::before {
                left: 0;
                right: 0;
                top: -20px;
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                z-index: -1;
                position: absolute;
              }

              &:hover {
                display: flex;

                & + .dropbtn {
                  color: #fece62;
                }
              }

              a {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
