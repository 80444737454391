@import "./variables";

.report-page-container {
  .tab-section {
    display: flex;
    background-color: #292929;

    .tab {
      opacity: 0.3;
      outline: none;
      color: #ececec;
      cursor: pointer;
      font-size: ptr(20px);
      min-width: ptr(180px);
      padding: ptr(12px) ptr(16px);
      border: 1px solid #292929;
      background-color: #323131ea;
      &.active {
        opacity: 1;
        background-color: #171717;
      }
    }
  }
  .content-section {
    margin-top: ptr(40px);
    .wrap {
    }
  }
}
