@import "./variables";

.Toastify__toast-container {


	.Toastify__toast-body {
		color: #FFF;
		font-weight: 700;
		font-style: normal;
		font-size: ptr(17px);
		line-height: ptr(22px);
		letter-spacing: -0.408px;
		font-family: $primary_font ;

	}

	.Toastify__toast-theme--light {
		background-color: #242C32;

	}

	.Toastify__toast--success {
		background: linear-gradient(270deg, rgba(36, 44, 50, 1) 60%, rgba(3, 87, 65, 1) 100%);
	}

	.Toastify__toast--error {
		background: linear-gradient(270deg, rgba(36, 44, 50, 1) 60%, rgba(59, 47, 53, 1) 100%);

		.Toastify__toast-icon {

			svg {
				fill: #F04248;
			}
		}

	}

	.Toastify__progress-bar--success {
		background: #01E17B
	}

	.Toastify__close-button {
		svg {
			fill: #fff;
		}
	}



	.Toastify__toast-icon {
		border-radius: 50%;
		width: ptr(32px);
		height: ptr(32px);
		border: 6px solid #2B4B48;

		svg {
			fill: #01E17B;
		}
	}
}