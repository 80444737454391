@import "../variables";

.adminbar {
  padding: ptr(8px) 0;
  margin-bottom: ptr(16px);
  background-color: #272829;
  .wrap {
    gap: ptr(8px);
    display: flex;
    justify-content: center;
  }
  .admin__search {
    display: flex;
    width: ptr(28px);
    transition: all 0.4s;
    background: #272829;
    border-radius: ptr(4px);
    &.active {
      width: ptr(200px);
      border: 1px solid rgba(130, 130, 130, 0.6);
      input {
        visibility: visible;
        padding: ptr(5px) ptr(8px);
      }
      .admin__search--button {
        height: ptr(26px);
      }
    }
    .admin__search--input {
      padding: 0;
      width: 100%;
      border: none;
      outline: none;
      color: #e2e2e2;
      background: none;
      font-weight: 400;
      height: ptr(26px);
      visibility: hidden;
      font-style: normal;
      font-size: ptr(12px);
      line-height: ptr(18px);
      letter-spacing: -0.12px;
      &::placeholder {
        color: #8f8f8f;
      }
    }
    .admin__search--button {
      border: none;
      display: block;
      width: ptr(28px);
      height: ptr(28px);
      background-color: transparent;
      img {
        width: ptr(10px);
        height: ptr(10px);
      }
    }
  }
}
