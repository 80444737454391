@import "./variables";
.user-report-container {
  padding: ptr(20px);
  border-radius: ptr(10px);
  background-color: #232323;
  .date-picker-sec {
    gap: ptr(16px);
    display: flex;
    align-items: center;
    background-color: #222222;
    padding-bottom: ptr(16px);

    .go-btn {
      outline: none;
      border: none;
      color: #232323;
      font-weight: 700;
      text-align: center;
      font-style: normal;
      background: $yellow;
      font-size: ptr(16px);
      min-width: ptr(101px);
      line-height: ptr(20px);
      border-radius: ptr(56px);
      padding: ptr(14px) ptr(32px);
    }
  }
  .user-select-outer {
    display: flex;
    gap: ptr(40px);
    align-items: center;
    margin-bottom: ptr(24px);
    .user-select {
      select {
        width: 100%;
        font-weight: 400;
        color: #d2d1cf;
        height: ptr(48px);
        border-radius: 1px;
        background: #222;
        font-style: normal;
        font-size: ptr(14px);
        max-width: ptr(432px);
        min-width: ptr(400px);
        line-height: ptr(14px);
        padding: 0 ptr(16px);
        border: 1px solid #3f3f3f;
      }
    }
    .details-btn {
      border: none;
      outline: none;
      background: none;
      color: #d2d1cf;
      padding: ptr(8px);
      transition: all 0.3s;
      border-radius: ptr(64px);
      text-decoration: underline;
      text-underline-offset: ptr(3px);
      &:hover {
        text-decoration: none;
        background-color: #34343460;
      }
    }
  }
  .user-details-wrapper {
    .individual-user-details {
      .user-details-top {
        display: flex;
        gap: ptr(40px);
        align-items: center;
        margin-bottom: ptr(20px);
      }
    }
    .client-list {
      padding: ptr(8px);
      border-radius: ptr(8px);
      background-color: #2b2a2a;
      .client-item {
        display: flex;
        margin-bottom: ptr(16px);
        border-bottom: 1px solid #3f3f3f;
        .client-name {
          color: #918b8b;
          min-width: ptr(180px);
        }
        .user-name {
          min-width: ptr(180px);
        }
        .project-list {
          max-width: ptr(500px);
          width: 100%;
          margin: 0 ptr(16px);
          .project-item {
            display: flex;
            gap: ptr(40px);
            font-size: ptr(14px);
            margin-bottom: ptr(4px);
            span {
              width: 50%;
              display: inline-block;
            }
          }
        }
      }
    }
    .user-list {
      .user-item {
        margin: ptr(16px) 0;
        .user-details {
          display: flex;
          margin-bottom: ptr(4px);
          .user-name {
            min-width: ptr(180px);
          }
        }
        .client-list {
          padding: ptr(8px);
          border-radius: ptr(8px);
          background-color: #2b2a2a;
          .client-item {
            margin-bottom: ptr(8px);
            &:nth-last-child(1) {
              margin-bottom: 0;
              border-bottom: 0;
            }
            .project-list {
              .project-item {
                margin-bottom: ptr(8px);
              }
            }
          }
          .client-name,
          .project-name,
          .time {
            color: #918b8b;
            font-size: ptr(12px);
          }
        }
      }
    }
  }
}
