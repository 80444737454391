@import "./variables";

.all-task-outer-wrapper {
  display: flex;
  gap: ptr(2px);
  flex-wrap: wrap;

  .task-item {
    display: flex;
    color: #ececec;
    font-weight: 400;
    align-items: center;
    font-size: ptr(20px);
    line-height: ptr(20px);
    letter-spacing: -0.2px;
    transition: all 0.4s;
    max-width: ptr(352px);
    justify-content: space-between;
    background-color: #292929;
    padding: ptr(18px) ptr(24px);
    width: calc((100% - ptr(8px))/3);



    button {
      margin: 0 ptr(24px);
    }

    .edit {
      input {
        padding: ptr(8px);
        border: 1px solid $white;
      }

      .rate-wrapper {
        display: flex;
        align-items: center;
        margin-top: ptr(8px);

        label {
          opacity: 0.8;
          font-size: ptr(14px);
          margin-right: ptr(12px);
        }
      }
    }

    .each-task {
      width: 100%;
      border: none;
      outline: none;
      color: #ececec;
      font-style: normal;
      font-size: ptr(20px);
      line-height: ptr(20px);
      letter-spacing: -0.2px;
      font-family: $primary_font;
      background-color: #292929;
    }

    button {
      border: none;
      outline: none;
      width: ptr(20px);
      height: ptr(20px);
      background-color: transparent;

      img {
        width: ptr(20px);
        height: ptr(20px);
      }
    }
  }

  .add-btn {
    display: flex;
    gap: ptr(6px);
    color: #fff;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: ptr(16px);
    align-items: center;
    margin-top: ptr(2px);
    justify-content: center;
    max-width: ptr(352px);
    padding: ptr(14px) ptr(32px);
    border: 1px solid #737373;
    background-color: #232323;
    width: calc((100% - ptr(8px))/3);
  }
}