@import "../variables";

.user {
  display: flex;
  gap: ptr(2px);
  align-items: center;
  .user__image {
    width: $ss;
    height: $ss;
    border-radius: 50%;
  }
  .user__name {
    color: #ccc;
    font-weight: 400;
    font-style: normal;
    font-size: $ss;
    line-height: ptr(16px)
  }
}
