@import "./variables";

.custom-popup-overlay {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(22, 22, 22, 0.9);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(8px);

  .content {
    gap: 32px;
    display: flex;
    width: (496px);
    height: auto;
    padding: 48px 32px;
    border-radius: 10px;
    background: #232323;
    flex-direction: column;
    p {
      text-align: left;
    }
    .button-container {
      display: flex;
      gap: ptr(24px);
      justify-content: flex-end;
      .btn {
        border: none;
        outline: none;
        font-weight: 700;
        color: #232323;
        font-style: normal;
        text-align: center;
        font-size: ptr(16px);
        background: $yellow;
        line-height: ptr(20px);
        border-radius: ptr(56px);
        padding: ptr(14px) ptr(32px);

        &.cancel {
          color: $white;
          background: #3f3f3f;
        }
      }
    }
  }
}
