@import "./variables";
@import "./reset";
@import "./common";

.add-project-btn {
  gap: 6px;
  color: #fff;
  border: none;
  outline: none;
  display: flex;
  font-size: 16px;
  cursor: pointer;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  font-style: normal;
  padding: 14px 32px;
  align-items: center;
  border-radius: 64px;
  transition: all 0.3s;
  background: #3f3f3f;
  justify-content: center;

  &:hover {
    color: #232323;
    background-color: $yellow;
  }
}

.overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  opacity: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  visibility: hidden;
  transition: all 1s;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end;
  backdrop-filter: blur(8px);
  background: rgba(22, 22, 22, 0.9);
  -webkit-backdrop-filter: blur(2px);

  &.active {
    right: 0;
    visibility: visible;
    opacity: 1;

    .content {
      right: 0;
      width: (400px);
    }
  }

  .close-btn-wrapper {
    z-index: 1;
    width: 560px;
    display: flex;
    top: ptr(16px);
    right: ptr(16px);
    position: absolute;
    justify-content: flex-end;

    .popup-close {
      border: none;
      cursor: pointer;
      background: none;
      width: ptr(20px);
      height: ptr(20px);
      border-radius: 50%;
    }
  }

  .content {
    width: 0;
    height: 100%;
    right: -100%;
    display: flex;
    filter: blur(0);
    transition: all 1s;
    position: relative;
    background: #232323;
    flex-direction: column;
    border-radius: ptr(10px);
    justify-content: flex-end;
    padding: ptr(48px) ptr(24px);

    .title {
      @extend .h2;
      text-align: left;
      color: #ececec;
      margin-bottom: ptr(16px);
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      label {
        color: #ccc;
        font-weight: 400;
        font-size: ptr(12px);
        line-height: ptr(24px);
      }

      select {
        width: 100%;
        font-size: 14px;
        cursor: pointer;
        font-weight: 400;
        color: #d2d1cf;
        line-height: 14px;
        padding: 17px 16px;
        background: #222;
        font-style: normal;
        margin-bottom: 24px;
        border-radius: 1px;
        border: 1px solid #3f3f3f;
      }

      button {
        border: none;
        outline: none;
        font-size: 16px;
        color: #232323;
        font-weight: 700;
        text-align: center;
        padding: 14px 32px;
        border-radius: 56px;
        line-height: 20px;
        font-style: normal;
        background: #fece62;
      }
    }

    input {
      width: 100%;
      max-width: 100%;
    }
  }
}
