@import "../variables";

.search__form {
  right: 0;
  display: flex;
  width: ptr(28px);
  position: absolute;
  transition: all 0.4s;
  background: #262626;
  border-radius: ptr(4px);
  &.active {
    right: 0;
    width: 100%;
    position: absolute;
    border: 1px solid rgba(130, 130, 130, 0.6);
    input {
      visibility: visible;
      padding: ptr(5px) ptr(8px);

    }
  }
  input {
    padding: 0;
    width: 100%;
    border: none;
    outline: none;
    color: #e2e2e2;
    background: none;
    font-weight: 400;
    height: ptr(36px);
    visibility: hidden;
    font-style: normal;
    font-size: ptr(12px);
    line-height: ptr(18px);
    letter-spacing: -0.12px;
    &::placeholder {
      color: #8f8f8f;
    }
  }
  .search__button {
    border: none;
    display: block;
    width: ptr(28px);
    height: ptr(36px);
    background-color: transparent;
    img {
      width: ptr(10px);
      height: ptr(10px);
    }
  }
}
