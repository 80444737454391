@import "./variables";

.all-client-outer-wrapper {
  display: flex;
  gap: ptr(2px);
  flex-wrap: wrap;
  flex-direction: column;

  .edit-btn,
  .save-btn {
    border: none;
    outline: none;
    width: ptr(20px);
    height: ptr(20px);
    background-color: transparent;

    img {
      width: ptr(20px);
      height: ptr(20px);
    }
  }

  .client-item {
    display: flex;
    width: 100%;
    align-items: center;
    max-width: ptr(552px);

    .each-row {
      width: 100%;

      .default-view {
        display: flex;
        align-items: center;
        padding-right: ptr(24px);
        background-color: #292929;
      }
    }

    .edit {
      width: 100%;
      display: flex;
      gap: ptr(24px);
      align-items: center;
      background-color: #292929;
      padding-right: ptr(24px);

      .client-header {
        border: 1px solid $white;
        background-color: #222222;
      }
    }

    .client-header {
      width: 100%;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      color: #ececec;
      font-weight: 400;
      text-align: left;
      align-items: center;
      font-style: normal;
      font-size: ptr(20px);
      line-height: ptr(20px);
      letter-spacing: -0.2px;
      background-color: #292929;
      padding: ptr(16px) ptr(24px);
      justify-content: space-between;

      img {
        width: ptr(24px);
        height: ptr(24px);
        display: block;
        transition: all 0.4s;
        transform: rotate(180deg);
      }

      &.expanded {
        img {
          transform: rotate(0deg);
        }
      }
    }

    .project-list {
      width: 100%;
      display: flex;
      gap: ptr(2px);
      max-height: 0;
      overflow: hidden;
      flex-direction: column;
      transition: all 1s ease-in-out;

      .project-item {
        width: 100%;
        display: flex;
        align-items: center;
        background: #383838;
        justify-content: space-between;

        .each-project {
          width: 100%;
          outline: none;
          color: inherit;
          font-size: inherit;
          font-weight: inherit;
          transition: all 0.4s;
          border: 1px solid $white;
          background-color: #383838;
          padding: ptr(14px) ptr(32px);
        }

        span {
          padding: ptr(14px) ptr(32px);
        }

        .edit-btn,
        .save-btn {
          margin: 0 ptr(32px);
        }
      }

      .add-btn {
        width: 100%;
      }
    }

    .project-list.expanded {
      max-height: ptr(5000px);
    }
  }

  .add-btn {
    display: flex;
    gap: ptr(6px);
    color: #fff;
    width: 100%;
    font-weight: 700;
    font-style: normal;
    text-align: center;
    font-size: ptr(16px);
    align-items: center;
    margin-top: ptr(2px);
    max-width: ptr(552px);
    justify-content: center;
    padding: ptr(14px) ptr(32px);
    border: 1px solid #737373;
    background-color: #232323;
  }

  .add-client-wrapper,
  .add-project-wrapper {
    opacity: 0;
    transition: all 1s;
    position: absolute;
    right: -100%;
    left: auto;
    width: 0;

    &.active {
      opacity: 1;
      right: 0;
    }
  }
}
