@import "./variables";
.p {
  font-weight: 500;
  font-size: ptr(16px);
  font-style: normal;
  line-height: ptr(24px);
  letter-spacing: -0.16px;
  font-family: $primary_font;
}
.sub-heading {
  font-weight: 700;
  line-height: 150%;
  font-style: normal;
  font-size: ptr(16px);
  letter-spacing: -0.16px;
  font-family: $primary_font;
}
.h2 {
  font-weight: 700;
  font-style: normal;
  font-size: ptr(32px);
  line-height: ptr(48px);
  letter-spacing: -0.32px;
  font-family: $primary_font;
}
button {
  cursor: pointer;
}
