@import "../variables";

.sort__button {
  cursor: pointer;
  width: ptr(28px);
  height: ptr(28px);
  position: relative;
  border-radius: ptr(4px);
  &:hover,
  &.active {
    background-color: #3a3a3a;
  }
  .sort__button--list-wrap {
    left: 50%;
    z-index: 100;
    top: ptr(34px);
    position: absolute;
    transform: translate(-50%,0);
    &::before {
      width: 0;
      left: 50%;
      height: 0;
      content: '';
      top: ptr(-5px);
      position: absolute;
      transform: translate(-50%,0);
      border-left: ptr(6px) solid transparent;
      border-right: ptr(6px) solid transparent;
      border-bottom: ptr(6px) solid #3A3A3A;
    }
  }
  .sort__button--list {
    overflow: auto;
    width: ptr(152px);
    background: #3A3A3A;
    max-height: ptr(300px);
    border-radius: ptr(4px);
    &::-webkit-scrollbar {
      width: ptr(3px);
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: ptr(3px);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    li {
      color: #ECECEC;
      font-weight: 400;
      line-height: 150%;
      font-size: ptr(12px);
      letter-spacing: -0.12px;
      padding: ptr(7px) ptr(8px);
      border-bottom: 1px solid rgba(77, 77, 77, 0.80);;
    }
    .sort__button--list-item {
      gap: ptr(4px);
      display: flex;
      .sort__button--profile {
        width: ptr(18px);
        height: ptr(18px);
        border-radius: 50%;
        border: 1px solid #7A7A7A;
        img {
          display: block;
          width: ptr(16px);
          height: ptr(16px);
          object-fit: cover;
          border-radius: 50%;
        }
      }
      &.active,
      &:hover {
        background-color: #474747;
      }
    }
  }
}
