@import "./variables";
@import "./reset";
@import "./common";
.project-report-container {
  padding: ptr(20px);
  border-radius: ptr(10px);
  background-color: #232323;
  .date-picker-sec {
    display: flex;
    gap: ptr(26px);
    align-items: center;
    background-color: #222222;
    padding-bottom: ptr(16px);
    .go-btn {
      outline: none;
      border: none;
      color: #232323;
      font-weight: 700;
      text-align: center;
      font-style: normal;
      background: $yellow;
      font-size: ptr(16px);
      min-width: ptr(101px);
      line-height: ptr(20px);
      border-radius: ptr(56px);
      padding: ptr(14px) ptr(32px);
    }
  }
  .project-details-wrapper {
    .details-btn {
      border: none;
      outline: none;
      background: none;
      color: #d2d1cf;
      padding: ptr(8px);
      transition: all 0.3s;
      margin-left: ptr(40px);
      border-radius: ptr(64px);
      text-decoration: underline;
      text-underline-offset: ptr(3px);
      &:hover {
        text-decoration: none;
        background-color: #34343460;
      }
    }
    select {
      width: 100%;
      font-weight: 400;
      color: #d2d1cf;
      height: ptr(48px);
      border-radius: 1px;
      background: #222;
      font-style: normal;
      font-size: ptr(14px);
      max-width: ptr(432px);
      line-height: ptr(14px);
      border: 1px solid #3f3f3f;
      padding: 0 ptr(16px);
    }
    .client-details-wrap {
      margin-top: ptr(16px);
      max-width: ptr(600px);
    }
    .client-details {
      display: flex;
      align-items: center;
      margin-bottom: ptr(4px);
      justify-content: space-between;
      .client-name {
        color: #ececec;
        font-weight: 500;
        line-height: 100%;
        font-style: normal;
        font-size: ptr(16px);
        letter-spacing: -0.16px;
      }
      .total-time-wrap {
        display: flex;
        flex-direction: column;
        .total-text {
          color: #ccc;
          font-weight: 500;
          font-style: normal;
          text-align: center;
          line-height: 100%;
          font-size: ptr(12px);
          letter-spacing: -0.14px;
          margin-bottom: ptr(4px);
        }
        .total-time {
          color: #ececec;
          font-weight: 400;
          text-align: center;
          font-style: normal;
          font-size: ptr(16px);
          line-height: ptr(16px);
          letter-spacing: -0.24px;
        }
      }
    }
    .project-list {
      padding: ptr(8px);
      border-radius: ptr(8px);
      background-color: #2b2a2a;
      li {
        display: flex;
        color: #918b8b;
        padding: ptr(4px) 0;
        align-items: center;
        margin-bottom: ptr(4px);
        justify-content: space-between;
        border-bottom: 1px solid #3f3f3f;
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
