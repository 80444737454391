@import "../variables";

.button {
  button {
    border: none;
    display: flex;
    gap: ptr(5px);
    font-weight: 500;
    text-align: center;
    font-size: ptr(14px);
    color: $pipelineBlack;
    line-height: ptr(18px);
    border-radius: ptr(4px);
    justify-content: center;
    background-color: #ECECEC;
    padding: ptr(11px) ptr(40px);
  }
  &.full-width {
    button {
      width: 100%;
    }
  }
  &.yellow {
    button {
      background-color: $yellow;
    }
  }
  &.green {
    button {
      background-color: $green;
    }
  }
  &.bordered {
    button {
      color: $textWhite;
      padding: ptr(10px) ptr(39px);
      background-color: transparent;
      border: 1px solid rgba(236, 236, 236, 0.25);
    }
  }
  &.strong {
    button {
      font-weight: 700;
    }
  }
}
