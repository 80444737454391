@import "./variables";
.nsm7Bb-HzV7m-LgbsSe.jVeSEe.i5vt6e-Ia7Qfc.uaxL4e-RbRzK {
  max-width: ptr(352px) !important;
  width: ptr(400px) !important;
}
.login-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .login-header {
    width: 100%;
    padding: ptr(16px) 0;
    border-bottom: 1px solid #222;
    .wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-weight: 700;
        line-height: 150%;
        font-style: normal;
        font-size: ptr(16px);
        letter-spacing: -0.16px;
        font-family: $primary_font;
        color: rgba(236, 236, 236, 0.5);
      }
    }
  }
  .login-outer-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc((100vh - ptr(64px)));
    .login-container {
      display: flex;
      width: ptr(472px);
      padding: ptr(24px);
      background: #232323;
      min-height: ptr(256px);
      border-radius: ptr(10px);
      flex-direction: column;
      justify-content: space-between;
      iframe {
        width: 100% !important;
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: $white;
        }
        #container.haAclf {
          width: 100% !important;
          max-width: 100% !important;
          .nsm7Bb-HzV7m-LgbsSe {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
      }

      img {
        width: 116px;
      }

      h2 {
        font-weight: 700;
        color: #ececec;
        font-style: normal;
        font-size: ptr(32px);
        line-height: ptr(48px);
        letter-spacing: -0.4px;
      }
      p {
        font-weight: 500;
        font-style: normal;
        font-size: ptr(16px);
        line-height: ptr(24px);
        letter-spacing: -0.16px;
        margin-bottom: ptr(24px);
        font-family: $primary_font;
        color: rgba(236, 236, 236, 0.6);
      }

      .btn-container {
        flex-grow: 1;
        display: flex;
        align-items: center;

        button {
          width: 100%;
          font-size: 16px;
          font-weight: 700;
          color: #232323 !important;
          max-width: fit-content;
          border-radius: 56px !important;
          background-color: #fece62 !important;

          div {
            border-radius: 56px !important;
          }

          span {
            padding: 14px 32px 14px 0 !important;
          }
        }
      }
    }
  }
}
