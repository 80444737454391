@import "../variables";

.task {
  padding: ptr(8px);
  background: #3f3f3f;
  border-radius: ptr(4px);
  .task__title {
    color: #fff;
    font-size: $bs;
    font-weight: 400;
    line-height: ptr(16px);
    margin-bottom: ptr(8px);
  }
  .task__details {
    display: flex;
    justify-content: space-between;
  }
  &.assigned {
    border: 1px dashed #666;
    background-color: transparent;
    .task__title {
      opacity: 0.5;
    }
    .task__details {
      opacity: 0.5;
    }
  }
}
