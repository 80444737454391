@use "sass:math";
@font-face {
  font-family: "PP Neue Montreal";
  src: url("../fonts/PPNeueMontreal-Medium.woff2") format("woff2"),
    url("../fonts/PPNeueMontreal-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PP Neue Montreal";
  src: url("../fonts/PPNeueMontreal-Bold.woff2") format("woff2"),
    url("../fonts/PPNeueMontreal-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PP Neue Montreal";
  src: url("../fonts/PPNeueMontreal-Regular.woff2") format("woff2"),
    url("../fonts/PPNeueMontreal-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

$primary_font: "PP Neue Montreal";
$boxed_content_width: 1360px;
$desktop-width: 1024px;
$tablet-width: 640px;

@function ptr($pxValue) {
  @return math.div($pxValue, 16px) * 1rem;
}

$ls: ptr(16px);
$bs: ptr(14px);
$ss: ptr(12px);

@mixin tablet {
  @media (max-width: #{$desktop-width - ptr(1px)}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

$black: #000;
$white: #fff;
$yellow: #fece62;
$textWhite: #ececec;
$green: #00C97A;
$pipelineBlack: #272829;
