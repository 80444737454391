@import "../variables";
.sidebar {
  width: 100%;
  flex-shrink: 0;
  max-width: ptr(240px);
  .sidebar__title {
    color: #AFAFAF;
    font-weight: 500;
    font-size: ptr(14px);
    line-height: ptr(16px);
    margin-bottom: ptr(8px);
    letter-spacing: -0.14px;
  }
  .sidebar__body {
    overflow: auto;
    padding-top: ptr(8px);
    background: #262626;
    border-radius: ptr(8px);
    border: 1px solid #404040;
  }
  .sidebar__body--wrap {
    padding: 0 ptr(8px) ptr(8px) ptr(8px);
  }
  .sidebar__taskcontainer {
    gap: ptr(8px);
    display: flex;
    overflow: auto;
    padding: 0 ptr(8px) ptr(8px) ptr(8px);
    flex-direction: column-reverse;
    height: calc(100vh - ptr(170px));
    &.addbutton {
      height: calc(100vh - ptr(218px));
    }
    &.filterbutton {
      height: calc(100vh - ptr(216px));
    }
    &.addbutton.filterbutton {
      height: calc(100vh - ptr(262px));
    }
    &::-webkit-scrollbar {
      width: ptr(6px);
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: ptr(10px);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
.pipeline-page.admin-page {
  .sidebar {
    .sidebar__taskcontainer {
      height: calc(100vh - ptr(230px));
      &.addbutton {
        height: calc(100vh - ptr(278px));
      }
      &.filterbutton {
        height: calc(100vh - ptr(276px));
      }
      &.addbutton.filterbutton {
        height: calc(100vh - ptr(322px));
      }
    }
  }
}
