@import "./scss/variables";
@import "./scss/reset";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.wrap {
  margin: 0 auto;
  max-width: ptr(1280px);
}

#root {
  min-height: 100vh;
}

.content-container {
  padding: ptr(32px) 0;
  position: relative;
  border-radius: ptr(10px);
  background-color: #232323;

  .btn-wrapper {
    gap: ptr(24px);
    display: flex;
    justify-content: flex-end;
    margin: ptr(32px) 0 ptr(16px) 0;

    .save-btn,
    .update-btn {
      border: none;
      outline: none;
      color: #232323;
      font-weight: 700;
      text-align: center;
      font-style: normal;
      font-size: ptr(16px);
      background: #fece62;
      line-height: ptr(20px);
      border-radius: ptr(56px);
      padding: ptr(14px) ptr(32px);
    }
  }
}
