@import "./variables";

.add-client,
.add-task,
.add-project-db {
  h2 {
    margin-bottom: 32px;
  }
  input {
    height: 48px;
    padding: 0 8px;
    font-size: 14px;
    max-width: 400px;
    color: #d2d1cf;
    font-weight: 400;
    border-radius: 1px;
    background: #222;
    line-height: 14px;
    font-style: normal;
    margin-bottom: 24px;
    border: 1px solid #3f3f3f;
  }

  button {
    outline: none;
    border: none;
    font-size: 16px;
    color: #232323;
    margin-top: 8px;
    font-weight: 700;
    line-height: 20px;
    font-style: normal;
    width: fit-content;
    text-align: center;
    padding: 14px 32px;
    border-radius: 56px;
    background-color: #fece62;
  }

  select {
    width: 100%;
    padding: 17px 16px;
    margin-bottom: 24px;
    border-radius: 1px;
    background: #222;
    color: #d2d1cf;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    border: 1px solid #3f3f3f;
  }
  label {
    display: block;
    margin-bottom: 4px;
  }
}

.admin-page {
  .top-sec {
    padding: ptr(24px) 0;
    background-color: #232323;
    h2 {
      font-weight: 700;
      color: #ececec;
      font-style: normal;
      font-size: ptr(32px);
      line-height: ptr(48px);
      letter-spacing: -0.32px;
      font-family: $primary_font;
    }
  }
  .tab-section {
    background-color: #161616;
    .wrap {
      display: flex;
      gap: ptr(24px);
      .tab {
        display: block;
        opacity: 0.3;
        color: #f5f5f5;
        cursor: pointer;
        font-weight: 500;
        font-style: normal;
        font-size: ptr(20px);
        transition: all 0.3s;
        line-height: ptr(24px);
        letter-spacing: -0.2px;
        font-family: $primary_font;
        padding: ptr(20px) ptr(16px);
        &.active {
          opacity: 1;
        }
      }
    }
  }
}
