@import "../variables";
.new-task-wrap {
  form {
    .title {
      width: 100%;
      border: none;
      color: $white;
      outline: none;
      font-size: 20px;
      font-weight: 500;
      color: #ececec;
      font-style: normal;
      line-height: ptr(30px);
      min-height: ptr(30px);
      letter-spacing: -0.2px;
      background-color: transparent;
      &::placeholder {
        color: #8f8f8f;
        font-weight: 500;
        font-style: normal;
        font-size: ptr(20px);
        line-height: ptr(30px);
        letter-spacing: -0.2px;
      }
    }
    .new-task-top {
      display: flex;
      gap: ptr(32px);
    }
    .new-task-top-left {
      width: 100%;
    }
    .task-assign-button {
      width: 100%;
      .assign-button-wrap {
        width: 100%;
        position: relative;
        max-width: ptr(183px);
        .user-list {
          width: 100%;
          z-index: 10;
          overflow: auto;
          left: ptr(24px);
          bottom: ptr(47px);
          position: absolute;
          max-width: ptr(152px);
          max-height: ptr(288px);
          border-radius: ptr(8px);
          background-color: #3A3A3A;
        }
        .user-list-item {
          gap: ptr(4px);
          display: flex;
          align-items: center;
          padding: ptr(7px) ptr(8px);
          border-bottom: 0.5px solid rgba(77, 77, 77, 0.80);
          &:last-child {
            border-bottom: none;
          }
          img {
            width: ptr(18px);
            height: ptr(18px);
            border-radius: 50%;
            border: 0.5px solid #7A7A7A;
          }
          span {
            color: #ECECEC;
            font-weight: 400;
            font-size: ptr(12px);
            line-height: ptr(16px);
            letter-spacing: -0.12px;
          }
        }
      }
      .done-btn {
        width: 100%;
        border: none;
        color: #272829;
        font-weight: 500;
        line-height: 100%;
        padding: ptr(13px);
        text-align: center;
        background: $green;
        font-size: ptr(14px);
        border-radius: ptr(4px);
      }
      .request-btn {
        width: 100%;
        color: #ECECEC;
        font-weight: 500;
        line-height: 100%;
        padding: ptr(12px);
        text-align: center;
        font-size: ptr(14px);
        max-width: ptr(170px);
        background: transparent;
        border-radius: ptr(4px);
        border: 1px solid rgba(236, 236, 236, 0.25);
      }
    }
    .task-title {
      color: #ECECEC;
      font-weight: 500;
      line-height: 150%;
      font-size: ptr(20px);
      letter-spacing: -0.2px;
      margin-bottom: ptr(8px);
    }
    .top-info {
      display: flex;
      gap: ptr(24px);
      align-items: center;
      margin-bottom: ptr(6px);
      .user-info {
        display: flex;
        align-items: center;
      }
      .profile-img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: ptr(16px);
          height: ptr(16px);
          border-radius: 50%;
        }
      }
    }
    .created-user-name {
      flex-shrink: 0;
      color: #ccc;
      font-size: $bs;
      font-weight: 400;
      font-style: normal;
      line-height: 150%;
      margin-left: ptr(4px);
      letter-spacing: -0.12px;
    }
    .deadline-wrap {
      gap: ptr(4px);
      display: flex;
      flex-shrink: 0;
      align-items: center;
      svg {
        display: block;
        width: ptr(14px);
        height: ptr(14px);
      }
      .date-format {
        color: #CCC;
        font-weight: 400;
        font-size: $bs;
        line-height: 150%;
        letter-spacing: -0.12px;
      }
    }
    .react-datepicker__input-container {
      width: 100%;
      position: relative;
      align-items: center;
      max-width: ptr(78px);
      &::after {
        top: 50%;
        right: 0;
        bottom: 0;
        content: "";
        display: block;
        width: ptr(10px);
        height: ptr(16px);
        position: absolute;
        pointer-events: none;
        background-position: center;
        background-repeat: no-repeat;
        transform: translateY(calc(-50% + ptr(3px)));
        background-image: url("../../../public/image/arrow-right.svg");
      }
      input {
        width: 100%;
        border: none;
        outline: none;
        color: #ccc;
        font-weight: 400;
        height: ptr(16px);
        font-style: normal;
        font-size: ptr(12px);
        line-height: ptr(16px);
        letter-spacing: -0.12px;
        background-color: transparent;
      }
    }
    .client-name-input {
      border: none;
      outline: none;
      color: $white;
      font-size: $bs;
      font-weight: 400;
      font-style: normal;
      line-height: 150%;
      letter-spacing: -0.12px;
      background-color: transparent;
      &::placeholder {
        color: #8f8f8f;
      }
    }
    .input-mention {
      width: 100%;
      min-height: ptr(61px);
      textarea {
        color: #ececec;
        line-height: 150%;
        background: #262626;
        border-radius: ptr(4px);
        letter-spacing: -0.14px;
        font-size: $bs !important;
        padding: ptr(8px) ptr(16px);
        border: 1px solid rgba(130, 130, 130, 0.60);
        &::placeholder {
          color: #8F8F8F;
        }
      }
      .input-mention__suggestions {
        width: 100%;
        overflow: auto;

        max-width: ptr(152px);
        max-height: ptr(200px);
        border-radius: ptr(8px);
        background-color: #3A3A3A !important;
        transform: translate(ptr(-7px),ptr(27px));
        .custom-mention {
          gap: ptr(4px);
          display: flex;
          align-items: center;
          padding: ptr(7px) ptr(8px);
          border-bottom: 0.5px solid rgba(77, 77, 77, 0.80);
          &:hover {
            background-color: #474747;
          }
          &:last-child {
            border-bottom: none;
          }
          img {
            width: ptr(18px);
            height: ptr(18px);
            border-radius: 50%;
            border: 0.5px solid #7A7A7A;;
          }
          span {
            color: #ECECEC;
            font-weight: 400;
            font-size: ptr(12px);
            line-height: ptr(16px);
            letter-spacing: -0.12px;
          }
        }
      }
    }
    .send-btn {
      border: none;
      outline: none;
      color: #272829;
      font-weight: 700;
      width: fit-content;
      text-align: center;
      font-style: normal;
      font-size: ptr(14px);
      margin-left: ptr(26px);
      background: #ececec;
      min-width: ptr(114px);
      line-height: ptr(14px);
      border-radius: ptr(4px);
      padding: ptr(12px) ptr(40px);
    }
    .client-name-input-container {
      position: relative;
      .client-list {
        left: 0;
        right: 0;
        z-index: 1;
        bottom: auto;
        gap: ptr(4px);
        display: flex;
        overflow: auto;
        padding: ptr(8px);
        position: absolute;
        max-height: ptr(120px);
        flex-direction: column;
        background: #3a3a3a;
        border-radius: ptr(4px);
        top: calc(100% + ptr(4px));
        border-bottom: 0.5px solid rgba(77, 77, 77, 0.8);
        .client-list-item {
          color: #ccc;
          font-weight: 400;
          font-style: normal;
          font-size: ptr(12px);
          line-height: ptr(16px);
          letter-spacing: -0.12px;
        }
      }
    }
    .client-name-nochangeable {
      color: #CCC;
      font-size: $bs;
      line-height: 150%;
      letter-spacing: -0.12px;

    }
    .textarea-wrap {
      width: 100%;
    }
    .recent-activities {
      h3 {
        color: #AFAFAF;
        font-weight: 500;
        line-height: 150%;
        font-size: $bs;
        opacity: 0.5;
        margin-bottom: ptr(8px);
        letter-spacing: -0.12px;
      }
      ul {
        overflow: auto;
        margin-bottom: ptr(24px);
        max-height: calc(100vh - ptr(457px));
        &.no-button {
          max-height: calc(100vh - ptr(340px));
        }
      }
      .each-recent-activity {
        position: relative;
        &::before {
          content: '';
          width: 0.5px;
          top: ptr(22px);
          left: ptr(11px);
          position: absolute;
          height: calc(100% - ptr(22px));
          background-color: rgba(122, 122, 122, 0.50);
        }
        &:first-child {
          .activity-meta {
            .activity-img-div {
              border: 0.5px solid #FFE7B1;
            }
          }
        }
        &:last-child {
          &::before {
            display: none;
          }
          .activies-container {
            padding-bottom: 0;
          }
        }
        .activity-meta {
          gap: ptr(4px);
          display: flex;
          align-items: center;
          margin-bottom: ptr(2px);
          .activity-img-div {
            display: flex;
            width: ptr(22px);
            height: ptr(22px);
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            border: 0.5px solid rgba(122, 122, 122, 0.50);
            img {
              display: block;
              width: ptr(16px);
              height: ptr(16px);
              object-fit: cover;
              border-radius: 50%;
            }
          }
          .assigned-user-name {
            color: #CCC;
            font-size: $ss;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.1px;
          }
          .seperator {
            width: ptr(3px);
            height: ptr(3px);
            border-radius: 50%;
            background-color: #737373;
          }
          .time-stamp {
            font-size: $ss;
            color: #A3A3A3;
            line-height: 150%;
            letter-spacing: -0.1px;
          }
        }
        .activies-container {
          color: $white;
          font-size: $bs;
          font-weight: 400;
          line-height: 150%;
          padding-left: ptr(26px);
          letter-spacing: -0.12px;
          white-space: break-spaces;
          padding-bottom: ptr(24px);
          a {
            color: #5E98E8;
          }
          .client,
          .assigned {
            color: $yellow;
          }
        }
      }
    }
    .input-comment-wrap {
      padding-left: ptr(26px);
    }
  }
  .form-button-div {
    display: flex;
    align-items: center;
    margin-top: ptr(16px);
    justify-content: space-between;
    .task-assign-button {
      display: flex;
      gap: ptr(10px);
      max-width: ptr(363px);
    }
  }
}

.pipeline-page.admin-page {
  .new-task-wrap {
    form {
      .recent-activities {
        ul {
          max-height: calc(100vh - ptr(525px));
          &.no-button {
            max-height: calc(100vh - ptr(408px));
          }
        }
      }
    }
  }
}
