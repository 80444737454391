@import "../variables";

.filter {
  gap: ptr(8px);
  display: flex;
  position: relative;
  justify-content: space-between;
  .filter_tabs {
    gap: ptr(8px);
    display: flex;
  }
}
